<!--
--------------------------------------------------------------------------------
<copyright file="BendingNumberOfBendsTimeline.vue" company="Bystronic Laser AG">
 Copyright (C) Bystronic Laser AG 2021-2024
</copyright>
--------------------------------------------------------------------------------
-->

<template>
  <section>
    <details>
      <summary>{{ $t('widget_info_text.X-axis') }}</summary>
      <p class="text">{{ $t('widget_info_text.scale_selected_period') }}</p>
      <p class="tip list-item tip-bkg">{{ $t('widget_info_text.tip') }}:</p>
      <ul class="list tip-bkg">
        <li class="list-item2">{{ $t('widget_info_text.bend_perform_part_tip_txt1') }}.</li>
        <li class="list-item2">{{ $t('widget_info_text.bars_tip_txt_cat') }}</li>
        <li class="list-item2">{{ $t('widget_info_text.tip_hist_txt') }}</li>
        <li class="list-item2">{{ $t('widget_info_text.tooltip_txt') }}</li>
      </ul>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.Y-axis') }}</summary>
      <p class="text">
        {{ $t('widget_info_text.number_of_bends_y_axis') }}
      </p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.lines') }}</summary>
      <p class="text">
        {{ $t('widget_info_text.number_of_bends_lines_txt') }}
      </p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.icons') }}</summary>
      <time-range-filter />
      <shift-filter />
      <group-x-axis-by />
    </details>
  </section>
</template>

<script language="ts">
import { Component, Vue } from 'vue-property-decorator';
import TimeRangeFilterInfo from '@/components/Charts/widgetInfo/TimeRangeFilter.vue';
import ShiftFilterInfo from '@/components/Charts/widgetInfo/ShiftFilter.vue';
import GroupXAxisByInfo from './GroupXAxisBy.vue';

@Component({
  components: {
    'time-range-filter': TimeRangeFilterInfo,
    'shift-filter': ShiftFilterInfo,
    'group-x-axis-by': GroupXAxisByInfo,
  },
})
export default class BendingNumberOfBendsTimeline extends Vue {}
</script>
